import React, { useEffect, useState, useMemo } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, getElementIcon } from '../utils';

import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import StyledWrapper from './style';

export const MUIEmail = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, isFormBuilder } = props;
	const [hasError, setHasError] = useState(props?.data?.error || false);

	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);
	const handleChange = () => {
		setHasError(false);
	};
	const styleForNotFormBuilder = !isFormBuilder
		? { width: '100%', marginTop: '16px' }
		: { width: '100%' };

	return (
		<StyledWrapper.FormControlNoBorder sx={{ width: '100%' }}>
			<StyledWrapper.CustomLabel disabled={disabled}>
				<>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{isFormBuilder && (
						<div>
							{props?.data?.unique_identifier
								? `(ff-${props?.data?.unique_identifier})`
								: `(tmp-${props?.data?.temp_order})`}
						</div>
					)}
					{props?.data?.required && <StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>}
				</>
			</StyledWrapper.CustomLabel>
			<TextField
				error={hasError}
				required={props?.data?.required}
				id="outlined-error-helper-text"
				name={name}
				inputRef={ref}
				disabled={disabled}
				defaultValue={defaultValue}
				style={styleForNotFormBuilder}
				InputLabelProps={{ shrink: isFormBuilder || undefined }}
				onChange={handleChange}
			/>
			<FormHelperText error={hasError}>
				{' '}
				{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
			</FormHelperText>
			{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
		</StyledWrapper.FormControlNoBorder>
	);
});

MUIEmail.displayName = 'MUI_Email';
Registry.register('MUI_Email', MUIEmail);
