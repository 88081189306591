/* eslint-disable */
import { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import FormValidator from './form-validator';
import FormElements from './form-elements';
import { TwoColumnRow, ThreeColumnRow, MultiColumnRow } from './multi-column';
import CustomElement from './form-elements/custom-element';
import Registry from './stores/registry';
import { setFormData, setFormErrors } from './slice';
import DependancyChecker from '../MultiFormViewer/DependancyChecker';
import { stripTextHTML } from '../FormBuilder/utils';
import { ControlledCheckbox } from '../core/Checkbox/TableCustomCheckbox';
import { setToReviewForms } from './slice';
import { validateDate, validateRequiredFields } from '../../utils/utilFunctions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { getOldBlueColor } from '../../utils/utilFunctions';

const { Image, Checkboxes, Signature, Download, Camera, FileUpload } = FormElements;

const ReactForm = (props) => {
	let form;

	let inputs = {};

	let answerData;

	answerData = _convert(props.answer_data);
	getDataById = getDataById.bind(this);
	const dispatch = useDispatch();
	const toReview = useSelector((state) => state?.form?.to_review);

	const _ = require('lodash');
	const formRef = useRef(null);
	const [savingErrors, setSavingErrors] = useState();
	const [allInvalidElements, setAllInvalidElements] = useState();
	const [lastInputTouched, setLastInputTouched] = useState();
	let isGrouping = false;
	let currentGroup = [];

	function _convert(answers) {
		if (Array.isArray(answers)) {
			const result = {};
			answers.forEach((x) => {
				result[x.name] = x.value;
			});
			return result;
		}
		return answers || {};
	}

	function _getDefaultValue(item) {
		return answerData[item?.field_name];
	}

	function _optionsDefaultValue(item) {
		const defaultValue = _getDefaultValue(item);
		if (defaultValue) {
			return defaultValue;
		}

		const defaultChecked = [];
		item.options.forEach((option) => {
			if (answerData[`option_${option.key}`]) {
				defaultChecked.push(option.key);
			}
		});
		return defaultChecked;
	}

	function _getOptionsRating(item, checked_options) {
		let ratingTotal = 0;
		checked_options?.forEach((e) => {
			const foundOption = item?.options?.find((option) => {
				return item?.key === 'StatePicker' ? option?.value === e : option?.key === e;
			});
			ratingTotal += Number(foundOption?.rating) || 0;
		});
		return ratingTotal;
	}

	function _getItemValue(item, ref) {
		let $item = {
			element: item.element,
			value: ''
		};
		if (item.element === 'Rating') {
			$item.value = ref.inputField.current.state.rating;
		} else if (item.element === 'Tags') {
			$item.value = ref.inputField.current.state.value;
		} else if (item.element === 'DatePicker') {
			$item.value = ref.state.value;
		} else if (item.element === 'Camera') {
			$item.value = ref.state.img;
		} else if (item.element === 'FileUpload') {
			$item.value = ref.state.fileUpload;
		} else if (item.field_name?.includes('signature_')) {
			const signature = document.getElementsByName(`${item.field_name}`)?.[1]?.value;
			$item.value = signature;
		} else if (['MUI_FileUpload', 'MUI_FileAttachment', 'MUI_Camera'].includes(item.key)) {
			const uploadPath = document.getElementById(`${item.field_name}-download`)?.value;

			$item.value = uploadPath?.replace(/^["']|["']$/g, '');
		} else if (item?.custom && item?.key === 'MUI_LinkInput') {
			const title = document.getElementsByName(`${item.field_name}-title`)[0]?.value;
			const link = document.getElementsByName(`${item.field_name}-link`)[0]?.value;
			$item.value = [title, link];
		} else if (item?.custom && item?.key === 'MUI_StartEndDate') {
			const start = document.getElementsByName(`${item.field_name}-start`)[0]?.value;
			const end = document.getElementsByName(`${item.field_name}-end`)[0]?.value;
			$item.value = [start, end];
		} else if (ref && ref.inputField && ref.inputField.current) {
			// NESTED CONDITION TO CHECK IF ELEMENT IS CUSTOM AND NEEDS FURTHER CONDITIONING TO GET REF VALUE
			if (item?.custom && item?.key !== 'MUI_Rating') {
				$item = ref.inputField.current;
			} else if (item?.custom && item?.key === 'MUI_Rating') {
				$item = {
					node: ref?.inputField?.current,
					value: ref?.inputField?.current?.dataset?.value
				};
			} else {
				$item = ReactDOM.findDOMNode(ref.inputField.current);
			}
			$item && typeof $item.value === 'string' && ($item.value = $item.value.trim());
		}
		return $item;
	}

	function _isIncorrect(item) {
		let incorrect = false;

		const ref = inputs[item.field_name];

		if (item.key === 'MUI_Checkbox' || item.key === 'MUI_RadioButton') {
			item.options.forEach((option) => {
				const optionElement = document.getElementsByName(`option_${option.key}`)?.[0];
				const $option = ReactDOM.findDOMNode(optionElement);
				if (option.hasOwnProperty('correct') && !$option.checked) {
					incorrect = true;
				}
			});
		}
		return incorrect;
	}

	function _isInvalid(item) {
		let invalid = false;
		if (item.required === true) {
			const ref = inputs[item.field_name];
			if (item.key === 'MUI_Checkbox' || item.key === 'MUI_RadioButton') {
				let checked_options = 0;
				item.options.forEach((option) => {
					const optionElement = document.getElementsByName(`option_${option.key}`)?.[0];
					const $option = ReactDOM.findDOMNode(optionElement);

					if ($option.checked) {
						checked_options += 1;
					}
				});
				if (checked_options < 1) {
					// errors.push(item.label + ' is required!');
					invalid = true;
				}
			} else if (item?.key === 'MUI_StartEndDate' || item?.key === 'MUI_LinkInput') {
				const values = _getItemValue(item)?.value;
				if (values?.[0] === '' || values?.[1] === '') {
					invalid = true;
				}
			} else {
				const $item = _getItemValue(item, ref);
				if (item.element === 'Rating') {
					if ($item.value === 0) {
						invalid = true;
					}
				} else if ($item.value === undefined || $item.value.length < 1) {
					invalid = true;
				}
			}
		}
		return invalid;
	}

	function _collect(item) {
		const itemData = {
			name: item.field_name,
			custom_name: item.custom_name || item.field_name,
			field: item.field_id
		};
		if (!itemData.name) return null;
		const ref = inputs[item.field_name];

		if (item?.key === 'MUI_Rating') {
			itemData.value = ref?.inputField?.current?.dataset?.value;
			itemData.rating = item.answer_rating;
		}

		if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
			const checked_options = [];
			item.options.forEach((option) => {
				const $option = ReactDOM.findDOMNode(ref.options[`child_ref_${option.key}`]);
				if ($option.checked) {
					checked_options.push(option.key);
				}
			});
			itemData.value = checked_options;
			itemData.rating = _getOptionsRating(item, checked_options);
		} else if (item?.key === 'MUI_Checkbox' || item?.key === 'MUI_RadioButton') {
			const checked_options = [];
			item.options.forEach((option) => {
				const $option = document.getElementsByName(`option_${option?.key}`)[0];
				if ($option.checked) {
					checked_options.push(option.key);
				}
			});
			itemData.value = checked_options;
			itemData.rating = _getOptionsRating(item, checked_options);
		} else {
			if (!ref) return null;
			itemData.value = _getItemValue(item, ref).value;
			itemData.rating =
				item?.key === 'MUI_Dropdown' || item?.key === 'StatePicker'
					? _getOptionsRating(item, [itemData.value])
					: item.answer_rating;
		}

		return itemData;
	}

	function hasInputChanged(e) {
		const newValue = e?.target?.value;
		const fieldName = e?.target?.name;

		const hasChanged = answerData[fieldName] !== newValue;
		if (hasChanged) {
			const element = document.getElementsByName(fieldName)[0];
			if (element) {
				const formGroupParent = element.closest('.form-group');
				if (!answerData[fieldName] && newValue === '') {
					formGroupParent.classList.remove('isChanged');
				} else if (formGroupParent) {
					formGroupParent.classList.add('isChanged');
				} else {
					formGroupParent.classList.remove('isChanged');
					console.error('Parent element with class .form-group not found.');
				}
			}
		}
	}

	function _collectFormData(data) {
		const formData = [];
		data.forEach((item) => {
			const item_data = _collect(item);

			if (item_data) {
				formData.push(item_data);
			}
		});
		return formData;
	}

	function _getSignatureImg(item) {
		const ref = inputs[item.field_name];
		const $canvas_sig = ref.canvas.current;
		if ($canvas_sig) {
			const base64 = $canvas_sig.toDataURL().replace('data:image/png;base64,', '');
			const isEmpty = $canvas_sig.isEmpty();
			const $input_sig = ReactDOM.findDOMNode(ref.inputField.current);
			if (isEmpty) {
				$input_sig.value = '';
			} else {
				$input_sig.value = base64;
			}
		}
	}

	function handleSubmit(e) {
		e?.preventDefault();

		let errors = [];
		// Separate simulate save and actual save
		if (props?.isSimulateSave) {
			const answerData = _collectFormData(props.data);

			const saveErrors = validateForm();
			const completeError = validateRequiredFields(props.data, answerData);
			errors = [...saveErrors, ...completeError];
			setSavingErrors(errors);

			if (errors.length < 1) {
				const { onSubmit } = props;

				if (onSubmit) {
					onSubmit(answerData);
				}
			}
		} else {
			if (props?.onValidationError) {
				errors = validateForm();
				// This is running always not just the new experience. So ignoreValidation will fix that
				// Publish errors, if any.
				props.completionErrors && props.setCompletionErrors([]);
				setSavingErrors(errors);
				dispatch(setFormErrors(errors));
				props?.onValidationError();
			}
			// Only submit if there are no errors.
			if (errors.length < 1) {
				const { onSubmit } = props;
				if (onSubmit) {
					const data = _collectFormData(props.data);
					props.activeStep === props.totalSteps?.length - 1 && onSubmit(data);
				}
			}
		}
	}

	function validateForm() {
		const errors = [];
		const invalidElements = [];
		let data_items = props.data;
		const { intl } = props;
		if (props.display_short) {
			data_items = props.data.filter((i) => i.alternateForm === true);
		}
		data_items.forEach((item) => {
			let idError;
			if (item && item.hasOwnProperty('id')) {
				// seems useless
				idError = item.id;
			} else {
				// actual save
				idError = item.field_id;
			}

			if (item.element === 'Signature') {
				_getSignatureImg(item);
			}

			if (item.key == 'MUI_DatePicker') {
				const ref = inputs[item.field_name];
				const dateValue = _getItemValue(item, ref).value;
				const { min_date, max_date } = ref.props.data;

				if (dateValue != '') {
					const checkDate = validateDate(dateValue);
					const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

					if (!checkDate) {
						errors.push({
							id: idError,
							field_name: item.field_name,
							label: item.label,
							error: `${intl.formatMessage({ id: 'message.invalid-date' })}`
						});
					}
					const formDate = new Date(dateValue);
					const minDate = min_date ? new Date(min_date) : null;
					const maxDate = max_date ? new Date(max_date) : null;
					const checkMinDate = minDate ? formDate >= minDate : true;
					const checkMaxDate = maxDate ? formDate <= maxDate : true;

					const minDateFormatted = minDate?.toLocaleDateString('en-US', options);
					const maxDateFormatted = maxDate?.toLocaleDateString('en-US', options);
					if (!checkMinDate || !checkMaxDate) {
						const errorDetails = {
							id: idError,
							field_name: item.field_name,
							label: item.label,
							error: ''
						};

						if (min_date && max_date) {
							errorDetails.error = `must be a date between ${minDateFormatted} and ${maxDateFormatted}`;
						} else if (min_date) {
							errorDetails.error = `must be a date later than ${minDateFormatted}`;
						} else if (max_date) {
							errorDetails.error = `must be a date earlier than ${maxDateFormatted}`;
						}

						errors.push(errorDetails);
					}
				}
			}

			if (item.key == 'MUI_StartEndDate') {
				const ref = inputs[item.field_name];
				const dateValue = _getItemValue(item, ref).value;
				const [startDate, endDate] = dateValue;
				if (startDate != '') {
					const checkDate = validateDate(startDate);

					if (!checkDate) {
						errors.push({
							id: idError,
							field_name: `${item.field_name}-start`,
							label: item.text,
							error: `${intl.formatMessage({ id: 'message.invalid-date' })}`
						});
					}
				}

				if (endDate != '') {
					const checkDate = validateDate(endDate);

					if (!checkDate) {
						errors.push({
							id: idError,
							field_name: `${item.field_name}-start`,
							label: item.text,
							error: `${intl.formatMessage({ id: 'message.invalid-date' })}`
						});
					}
				}

				if (startDate != '' && endDate != '' && startDate > endDate) {
					errors.push({
						id: idError,
						field_name: `${item.field_name}-start`,
						label: item.text,
						error: `Start date must be earlier than end date`
					});
				}
			}
			if (item.element === 'EmailInput' || item.key === 'MUI_Email') {
				const ref = inputs[item.field_name];
				const emailValue = _getItemValue(item, ref).value;
				if (emailValue) {
					const validateEmail = (email) =>
						email.match(
							// eslint-disable-next-line no-useless-escape
							/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						);
					const checkEmail = validateEmail(emailValue);
					if (!checkEmail) {
						errors.push({
							id: idError,
							field_name: `${item.field_name}`,
							label: item.label,
							error: `${intl.formatMessage({ id: 'message.invalid-email' })}`
						});
					}
				}
			}

			if (item.element === 'PhoneNumber' || item.key === 'MUI_PhoneNumber') {
				const ref = inputs[item.field_name];
				const phoneValue = _getItemValue(item, ref).value;
				if (phoneValue) {
					const validatePhone = (phone) =>
						phone.match(/^(\+1\-|\+1 |\+1)?[0-9]{3}-[0-9]{3}-[0-9]{4}$/g);
					const checkPhone = validatePhone(phoneValue);
					if (!checkPhone) {
						errors.push({
							id: idError,
							field_name: item.field_name,
							label: item.label,
							error: `${intl.formatMessage({ id: 'message.invalid-phone-number' })}`
						});
					}
				}
			}

			if (item?.key === 'MUI_LinkInput') {
				const ref = inputs[item.field_name];
				const inputValue = _getItemValue(item, ref).value;
				const exteralLink = inputValue[1];
				if (exteralLink) {
					const isValidUrl = (urlString) => {
						try {
							return Boolean(new URL(urlString));
						} catch (e) {
							return false;
						}
					};
					if (!isValidUrl(exteralLink)) {
						errors.push({
							id: idError,
							field_name: `${item.field_name}-title`,
							label: item.label,
							error: `${intl.formatMessage({ id: 'message.invalid-link' })}`
						});
					}
				}
			}
			if (['MUI_Checkbox', 'MUI_RadioButton'].includes(item.key) && item.hasOwnProperty('id')) {
				if (item.required) {
					let checked_options = 0;
					item.options.forEach((option) => {
						const optionElement = document.getElementsByName(`option_${option.key}`)?.[0];
						const $option = ReactDOM.findDOMNode(optionElement);
						if ($option.checked) {
							checked_options += 1;
						}
					});
					if (checked_options < 1) {
						errors.push({
							id: idError,
							field_name: item.field_name,
							label: item.label,
							error: `${intl.formatMessage({ id: 'message.is-required' })}`
						});
					}
				}
			}
			if (['StatePicker', 'MUI_Dropdown'].includes(item.key) && item.hasOwnProperty('id')) {
				if (item.required && !document.getElementsByName(`${item.field_name}`)?.[0]?.value) {
					errors.push({
						id: idError,
						field_name: item.field_name,
						label: item.label,
						error: `${intl.formatMessage({ id: 'message.is-required' })}`
					});
				}
			}

			if (item?.key === 'MUI_NumberInput') {
				if (item.hasOwnProperty('id')) {
					// simulate save
					if (item.required) {
						const ref = inputs[item.field_name];
						const inputValue = _getItemValue(item, ref).value;
						let underMin = item?.min_value && inputValue < parseInt(item?.min_value || '');
						let overMax = item?.max_value && inputValue > parseInt(item?.max_value || '');
						// Control whether they are undefined or not so that I don't have to write more conditions
						underMin = underMin === '' ? undefined : underMin;
						overMax = overMax === '' ? undefined : overMax;
						if (underMin || overMax) {
							if (underMin !== undefined && overMax !== undefined) {
								errors.push({
									id: idError,
									field_name: item.field_name,
									label: item.label,
									error: `${intl.formatMessage({
										id: 'must be between ' + item?.min_value + ' and ' + item?.max_value
									})}`
								});
							} else if (underMin === undefined && overMax !== undefined) {
								errors.push({
									id: idError,
									field_name: item.field_name,
									label: item.label,
									error: `${intl.formatMessage({
										id: 'must be under ' + item?.max_value
									})}`
								});
							} else if (underMin !== undefined && overMax === undefined) {
								errors.push({
									id: idError,
									field_name: item.field_name,
									label: item.label,
									error: `${intl.formatMessage({
										id: 'must be over ' + item?.min_value
									})}`
								});
							}
						}
					}
				} else {
					// actual save
					const ref = inputs[item.field_name];
					const inputValue = _getItemValue(item, ref).value;
					if (inputValue != '') {
						let underMin = item?.min_value && inputValue < parseInt(item?.min_value || '');
						let overMax = item?.max_value && inputValue > parseInt(item?.max_value || '');
						// Control whether they are undefined or not so that I don't have to write more conditions
						underMin = underMin === '' ? undefined : underMin;
						overMax = overMax === '' ? undefined : overMax;
						if (underMin || overMax) {
							if (underMin !== undefined && overMax !== undefined) {
								errors.push({
									id: idError,
									field_name: item.field_name,
									label: item.label,
									error: `${intl.formatMessage({
										id: 'must be between ' + item?.min_value + ' and ' + item?.max_value
									})}`
								});
							} else if (underMin === undefined && overMax !== undefined) {
								errors.push({
									id: idError,
									field_name: item.field_name,
									label: item.label,
									error: `${intl.formatMessage({
										id: 'must be under ' + item?.max_value
									})}`
								});
							} else if (underMin !== undefined && overMax === undefined) {
								errors.push({
									id: idError,
									field_name: item.field_name,
									label: item.label,
									error: `${intl.formatMessage({
										id: 'must be over ' + item?.min_value
									})}`
								});
							}
						}
					}
				}
			}

			if (item?.can_have_option_correct && _isIncorrect(item)) {
				errors.push({
					id: idError,
					field_name: item.field_name,
					label: item.label,
					error: `${item.label} ${intl.formatMessage({ id: 'message.was-answered-incorrectly' })}!`
				});
			}
		});

		setAllInvalidElements(invalidElements);
		return errors;
	}

	function getDataById(id) {
		const { data } = props;
		return data.find((x) => {
			const itemID = x?.field_id || x?.id;
			return itemID?.toLowerCase() === id?.toLowerCase();
		});
	}

	function getInputElement(item) {
		if (item?.custom) {
			return getCustomElement(item);
		}

		const Input = FormElements[item?.element];
		return (
			<Input
				// handleChange={this.handleChange}
				ref={(c) => (inputs[item?.field_name] = c)}
				mutable={true}
				key={`form_${item?.id || item?.field_id}`}
				data={item}
				read_only={props?.read_only}
				defaultValue={_getDefaultValue(item)}
			/>
		);
	}

	function getContainerElement(item, Element) {
		const controls = item.child_items.map((x) => {
			const data = getDataById(x);
			return x ? (
				<>
					<span
						key={`individual-form-${data?.field_id}`}
						style={{
							display: 'flex',
							border:
								props?.enableSelection &&
								isInputSelected(data?.field_id) &&
								`1px solid ${getOldBlueColor()}`,
							alignItems: 'center',
							padding: props?.enableSelection && '0px 10px 0px 0px',
							marginBottom: props?.enableSelection && '10px',
							borderRadius: '10px',
							height: 'fit-content',
							width: '100%'
						}}
					>
						{props?.enableSelection && (
							<ControlledCheckbox
								isReviewStage={true}
								toReviewDependancy={isInputSelected}
								disabled={props?.selectionsDisabled}
								id={data?.field_id}
								isChecked={() => isInputSelected(data?.field_id)}
								onChange={() => {
									const tempToReview = [...toReview];
									const currentFormToReview = tempToReview?.find(
										(e) => e?.formId === props?.formId
									);
									const currentFormToReviewIndex = tempToReview?.findIndex(
										(e) => e?.formId === props?.formId
									);
									if (currentFormToReview) {
										const currentInputs = [...currentFormToReview?.review_inputs];

										const inputExists = currentInputs.findIndex((x) => x === data?.field_id);

										if (inputExists >= 0) {
											currentInputs?.splice(inputExists, 1);
											data?.child_items?.map((e) => {
												const childIndex = currentInputs.findIndex(
													(x) => x === e?.toLowerCase() || x === e
												);
												currentInputs?.splice(childIndex, 1);
											});

											const includesAnyElement = currentInputs.some((element) =>
												item.child_items.includes(element.toUpperCase())
											);
											if (!includesAnyElement) {
												const containerIndex = currentInputs.findIndex((x) => x === item?.field_id);
												if (containerIndex >= 0) {
													currentInputs?.splice(containerIndex, 1);
												}
											}
										} else {
											currentInputs?.push(data?.field_id);
											const containerIndex = currentInputs.findIndex((x) => x === item?.field_id);
											if (containerIndex < 0) currentInputs?.push(item?.field_id);
											const lowercaseChildIds = data?.child_items?.map((e) => e?.toLowerCase());
											lowercaseChildIds && currentInputs?.push(...lowercaseChildIds);
										}

										if (currentInputs?.length > 0) {
											tempToReview[currentFormToReviewIndex] = {
												formId: props?.formId,
												review_inputs: currentInputs
											};
										} else {
											tempToReview?.splice(currentFormToReviewIndex, 1);
										}
										dispatch(setToReviewForms(tempToReview));
									} else {
										if (data?.child_items) {
											dispatch(
												setToReviewForms([
													...tempToReview,
													{
														formId: props?.formId,
														review_inputs: [data?.field_id, ...data?.child_items]
													}
												])
											);
										} else {
											dispatch(
												setToReviewForms([
													...tempToReview,
													{
														formId: props?.formId,
														review_inputs: [item?.field_id, data?.field_id]
													}
												])
											);
										}
									}
								}}
							/>
						)}
						<div style={{ width: '100%' }}>{getInputElement(getDataById(x))}</div>
					</span>
				</>
			) : (
				<div>&nbsp;</div>
			);
		});
		return <Element mutable={true} key={`form_${item.id}`} data={item} controls={controls} />;
	}

	function getSimpleElement(item) {
		const Element = FormElements[item.element];
		return <Element mutable={true} key={`form_${item.id}`} data={item} />;
	}

	function getCustomElement(itemOld) {
		const { intl } = props;
		let item = _.cloneDeep(itemOld);
		if (!item.component || typeof item.component !== 'function') {
			item.component = Registry.get(item.key);
			if (!item.component) {
				console.error(
					`${item.element} ${intl.formatMessage({ id: 'message.was-not-registered' })}`
				);
			}
		}

		const inputProps = item.forward_ref && {
			// handleChange: this.handleChange,
			defaultValue:
				item?.key === 'MUI_Checkbox' || item?.key === 'MUI_RadioButton' || item?.key === 'MUI_Tags'
					? _optionsDefaultValue(item)
					: _getDefaultValue(item),
			ref: (c) => (inputs[item.field_name] = c)
		};

		let idError;
		if (item && item.hasOwnProperty('id')) {
			idError = item.id;
		} else {
			idError = item.field_id;
		}
		const hasError =
			props?.completionErrors?.length > 0
				? props.completionErrors?.find((e) => e?.id === idError)
				: savingErrors?.find((e) => e?.id === idError);
		item.error = hasError ? hasError : false;
		return (
			<CustomElement
				mutable={true}
				read_only={props.read_only}
				key={`form_${item?.field_id || item?.id}`}
				data={item}
				{...inputProps}
			/>
		);
	}

	let data_items = props.data;
	if (props.display_short) {
		data_items = props.data.filter((i) => i.alternateForm === true);
	}

	data_items?.forEach((item) => {
		if (item && item.read_only && item.variableKey && props.variables[item.variableKey]) {
			answerData[item.field_name] = props.variables[item.variableKey];
		}
	});

	const getItem = (item) => {
		switch (item.element) {
			case 'TextInput':
			case 'EmailInput':
			case 'PhoneNumber':
			case 'NumberInput':
			case 'TextArea':
			case 'Dropdown':
			case 'DatePicker':
			case 'RadioButtons':
			case 'Rating':
			case 'Tags':
			case 'Range':
				return getInputElement(item);
			case 'CustomElement':
				return getCustomElement(item);
			case 'MultiColumnRow':
				return getContainerElement(item, MultiColumnRow);
			case 'ThreeColumnRow':
				return getContainerElement(item, ThreeColumnRow);
			case 'TwoColumnRow':
				return getContainerElement(item, TwoColumnRow);
			case 'Signature':
				const hasError =
					savingErrors == null
						? props.completionErrors?.find((e) => e?.id === item.field_id)
						: savingErrors?.find((e) => e?.id === item.field_id);
				return (
					<Signature
						hasError={hasError}
						ref={(c) => (inputs[item.field_name] = c)}
						read_only={props.read_only || item.read_only}
						mutable={true}
						key={`form_${item.id}`}
						data={item}
						defaultValue={_getDefaultValue(item)}
					/>
				);
			case 'Checkboxes':
				return (
					<Checkboxes
						ref={(c) => (inputs[item.field_name] = c)}
						read_only={props.read_only}
						// handleChange={this.handleChange}
						mutable={true}
						key={`form_${item.id}`}
						data={item}
						defaultValue={_optionsDefaultValue(item)}
					/>
				);
			case 'Image':
				return (
					<Image
						ref={(c) => (inputs[item.field_name] = c)}
						// handleChange={this.handleChange}
						mutable={true}
						key={`form_${item.id}`}
						data={item}
						defaultValue={_getDefaultValue(item)}
						read_only={props.read_only}
					/>
				);
			case 'Download':
				return (
					<Download
						download_path={props.download_path}
						mutable={true}
						key={`form_${item.id}`}
						data={item}
					/>
				);
			case 'Camera':
				return (
					<Camera
						ref={(c) => (inputs[item.field_name] = c)}
						read_only={props.read_only || item.read_only}
						mutable={true}
						key={`form_${item.id}`}
						data={item}
						defaultValue={_getDefaultValue(item)}
					/>
				);
			case 'FileUpload':
				return (
					<FileUpload
						ref={(c) => (inputs[item.field_name] = c)}
						read_only={props.read_only || item.read_only}
						mutable={true}
						key={`form_${item.id}`}
						data={item}
						defaultValue={_getDefaultValue(item)}
					/>
				);
			default:
				return getSimpleElement(item);
		}
	};

	const addToGrouping = (item) => {
		const currentItem = item;
		currentGroup.push(currentItem);
	};

	const closeGrouping = () => {
		isGrouping = false;
		const start = currentGroup[0];
		const end = currentGroup[currentGroup.length - 1];
		const groupIds = currentGroup.map((obj) => obj.field_id);
		const groupIds_sliced = groupIds.slice(1, -1);
		const childIds = currentGroup.flatMap((obj) => obj?.child_items).filter((childId) => childId);
		return (
			<div style={{ display: 'flex', gap: '15px', height: 'fit-content' }}>
				<div
					style={{
						margin: '10px 0px',
						width: '3px',
						height: 'auto',
						background: '#ECEFF1',
						borderRadius: '13px'
					}}
				/>
				<div style={{ width: '100%' }}>
					{currentGroup?.map((e, idx) => {
						if (e.key == 'MUI_GroupingEnd') {
							return getItem(e);
						}

						if (e.key != 'MUI_GroupingStart' && e.key != 'MUI_GroupingEnd') {
							return (
								<span
									key={`individual-form-${e?.field_id}`}
									style={{
										display: 'flex',
										border:
											props?.enableSelection &&
											isInputSelected(e?.field_id) &&
											e?.element != 'TwoColumnRow' &&
											e?.element != 'ThreeColumnRow' &&
											e?.element != 'MultiColumnRow' &&
											`1px solid ${getOldBlueColor()}`,
										alignItems: 'center',
										padding: props?.enableSelection && '0px 10px 0px 0px',
										marginBottom: props?.enableSelection && '10px',
										borderRadius: '10px',
										height: 'fit-content',
										width: '100%'
									}}
								>
									{props?.enableSelection &&
										e?.element != 'TwoColumnRow' &&
										e?.element != 'ThreeColumnRow' &&
										e?.element != 'MultiColumnRow' && (
											<ControlledCheckbox
												isReviewStage={true}
												toReviewDependancy={isInputSelected}
												disabled={props?.selectionsDisabled}
												id={e?.field_id}
												isChecked={() => isInputSelected(e?.field_id)}
												onChange={() => {
													const tempToReview = [...toReview];
													const currentFormToReview = tempToReview?.find(
														(f) => f?.formId === props?.formId
													);
													const currentFormToReviewIndex = tempToReview?.findIndex(
														(f) => f?.formId === props?.formId
													);
													if (currentFormToReview) {
														const currentInputs = [...currentFormToReview?.review_inputs];

														const inputExists = currentInputs.findIndex((x) => x === e?.field_id);
														if (inputExists >= 0) {
															currentInputs?.splice(inputExists, 1);

															e?.child_items?.map((e) => {
																const childIndex = currentInputs.findIndex(
																	(x) => x === e?.toLowerCase() || x === e
																);
																currentInputs?.splice(childIndex, 1);
															});
															const includesAnyElement = currentInputs.some(
																(element) =>
																	groupIds_sliced.includes(element) || childIds.includes(element)
															);
															if (!includesAnyElement) {
																const startIndex = currentInputs.findIndex(
																	(x) => x === start?.field_id
																);
																if (startIndex >= 0) currentInputs?.splice(startIndex, 1);
																const endIndex = currentInputs.findIndex(
																	(x) => x === end?.field_id
																);
																if (endIndex >= 0) currentInputs?.splice(endIndex, 1);
															}
														} else {
															currentInputs?.push(e?.field_id);
															const startIndex = currentInputs.findIndex(
																(x) => x === start?.field_id
															);
															if (startIndex < 0) currentInputs?.push(start?.field_id);
															const endIndex = currentInputs.findIndex((x) => x === end?.field_id);
															if (endIndex < 0) currentInputs?.push(end?.field_id);

															const lowercaseChildIds = e?.child_items?.map((e) =>
																e?.toLowerCase()
															);
															lowercaseChildIds && currentInputs?.push(...lowercaseChildIds);
														}
														if (currentInputs?.length > 0) {
															tempToReview[currentFormToReviewIndex] = {
																formId: props?.formId,
																review_inputs: currentInputs
															};
														} else {
															tempToReview?.splice(currentFormToReviewIndex, 1);
														}
														dispatch(setToReviewForms(tempToReview));
													} else {
														if (e?.child_items) {
															dispatch(
																setToReviewForms([
																	...tempToReview,
																	{
																		formId: props?.formId,
																		review_inputs: [
																			start.field_id,
																			e?.field_id,
																			...e?.child_items,
																			end.field_id
																		]
																	}
																])
															);
														} else {
															dispatch(
																setToReviewForms([
																	...tempToReview,
																	{
																		formId: props?.formId,
																		review_inputs: [start.field_id, e?.field_id, end.field_id]
																	}
																])
															);
														}
													}
												}}
											/>
										)}
									<div style={{ width: '100%' }}>{getItem(e)}</div>
								</span>
							);
						}
					})}
				</div>
			</div>
		);
	};

	const isInputSelected = (field_id) => {
		const currentFormToReview = toReview?.find((e) => e?.formId === props?.formId);
		if (currentFormToReview) {
			return (
				currentFormToReview?.review_inputs?.length === 0 ||
				currentFormToReview?.review_inputs?.includes(field_id)
			);
		} else {
			return false;
		}
	};

	const handleBlur = (event, props) => {
		const name = getNameForCounter(event);
		if (name) {
			const formSchema = props.data.find((e) => e?.field_name === name);
			if (formSchema && formSchema.limit_value) {
				Array.from(event.target.parentElement.children).forEach(function (item) {
					if (item.getAttribute('name') === 'counter') {
						item.remove();
					}
				});
			}
		}
	};

	const getNameForCounter = (event) => {
		// text Email
		// For the statepicker dropdown getAttribute is not a function. So we need to check before. Otherwise we will get an error.
		let name = null;
		if (typeof event?.target?.getAttribute === 'function') {
			name = event?.target?.getAttribute('name');
		} else {
			return name;
		}

		if (!name) {
			// text Area, text Input
			name = event.target.parentElement.parentElement.getAttribute('name');
		}
		return name;
	};

	const addOrUpdateCounter = (event, props) => {
		const name = getNameForCounter(event);
		if (name) {
			const formSchema = props.data.find((e) => e?.field_name === name);
			if (formSchema && formSchema.limit_value) {
				if (event.target.value.length > formSchema.limit_value) {
					event.target.value = event.target.value.substring(0, formSchema.limit_value);
				}
				let counter = null;
				Array.from(event.target.parentElement.children).forEach(function (item) {
					if (item.getAttribute('name') === 'counter') {
						counter = item;
					}
				});
				if (!counter) {
					counter = document.createElement('span');
					counter.classList.add('counter');
					counter.setAttribute('name', 'counter');
					event.target.parentElement.appendChild(counter);
				}
				if (counter) {
					counter.innerHTML =
						'<span class="counter-alert">Max characters reached! </span><span class="counter-value">' +
						event.target.value.length +
						'/' +
						formSchema.limit_value +
						'</span>';
					if (event.target.value.length < formSchema.limit_value) {
						counter.classList.remove('has-limit');
						counter.classList.add('no-limit');
					} else {
						counter.classList.remove('no-limit');
						counter.classList.add('has-limit');
					}
				}
			}
		}
	};

	const items = data_items
		?.map((item) => {
			if (item.parent_id) return null;
			if (!item) return null;
			if (isGrouping === false) {
				currentGroup = [];
			}

			switch (item.key) {
				case 'MUI_GroupingStart':
					isGrouping = true;
					if (!props.funded) {
						addToGrouping(item);
					}
					return getItem(item);
				case 'MUI_GroupingEnd':
					addToGrouping(item);
					return closeGrouping();
			}
			if (isGrouping) {
				addToGrouping(item);
				return null;
			}
			return (
				<span
					key={`individual-form-${item?.field_id}`}
					style={{
						display: 'flex',
						border:
							props?.enableSelection &&
							isInputSelected(item?.field_id) &&
							item.element != 'TwoColumnRow' &&
							item.element != 'ThreeColumnRow' &&
							item.element != 'MultiColumnRow' &&
							`1px solid ${getOldBlueColor()}`,
						alignItems: 'center',
						gap: '10px',
						width: '100%',
						padding: props?.enableSelection && '0px 10px 0px 0px',
						marginBottom: props?.enableSelection && '10px',
						borderRadius: '10px'
					}}
				>
					{props?.enableSelection &&
						item.element != 'TwoColumnRow' &&
						item.element != 'ThreeColumnRow' &&
						item.element != 'MultiColumnRow' && (
							<ControlledCheckbox
								isReviewStage={true}
								toReviewDependancy={isInputSelected}
								disabled={props?.selectionsDisabled}
								id={item?.field_id}
								isChecked={() => isInputSelected(item?.field_id)}
								onChange={() => {
									const tempToReview = [...toReview];
									const currentFormToReview = tempToReview?.find(
										(e) => e?.formId === props?.formId
									);
									const currentFormToReviewIndex = tempToReview?.findIndex(
										(e) => e?.formId === props?.formId
									);

									if (currentFormToReview) {
										const currentInputs = [...currentFormToReview?.review_inputs];

										const inputExists = currentInputs.findIndex((x) => x === item?.field_id);

										if (inputExists >= 0) {
											currentInputs?.splice(inputExists, 1);
											item?.child_items?.map((e) => {
												const childIndex = currentInputs.findIndex(
													(x) => x === e?.toLowerCase() || x === e
												);
												currentInputs?.splice(childIndex, 1);
											});
										} else {
											currentInputs?.push(item?.field_id);
											const lowercaseChildIds = item?.child_items?.map((e) => e?.toLowerCase());
											lowercaseChildIds && currentInputs?.push(...lowercaseChildIds);
										}

										if (currentInputs?.length > 0) {
											tempToReview[currentFormToReviewIndex] = {
												formId: props?.formId,
												review_inputs: currentInputs
											};
										} else {
											tempToReview?.splice(currentFormToReviewIndex, 1);
										}
										dispatch(setToReviewForms(tempToReview));
									} else {
										if (item?.child_items) {
											dispatch(
												setToReviewForms([
													...tempToReview,
													{
														formId: props?.formId,
														review_inputs: [item?.field_id, ...item?.child_items]
													}
												])
											);
										} else {
											dispatch(
												setToReviewForms([
													...tempToReview,
													{
														formId: props?.formId,
														review_inputs: [item?.field_id]
													}
												])
											);
										}
									}
								}}
							/>
						)}
					<div style={{ width: '100%' }}>{getItem(item)}</div>
				</span>
			);
		})
		.filter((x) => x);

	const formTokenStyle = {
		display: 'none'
	};
	return (
		<div>
			<DependancyChecker
				formSchema={props?.data}
				answers={props?.answer_data}
				lastInputTouched={lastInputTouched}
			/>

			<FormValidator
				errors={props?.completionErrors?.length > 0 ? props.completionErrors : savingErrors}
				formRef={formRef}
			/>
			<div className="react-form-builder-form multiStep-formViewer" ref={formRef || props.formRef}>
				<form
					id={props?.formID || 'userForm'}
					className="formBuilder-form"
					encType="multipart/form-data"
					ref={(c) => (form = c)}
					action={props.form_action}
					onSubmit={() => handleSubmit.bind(this)}
					onChange={(e) => {
						if (e?.target?.name?.includes('option_')) {
							dispatch(setFormData([props.formIndex, _collectFormData(props.data)]));
							setLastInputTouched(e);
						}
						addOrUpdateCounter(e, props);
						props?.onDirty();
					}}
					method={props.form_method}
					onBlur={(e) => {
						hasInputChanged(e);
						dispatch(setFormData([props.formIndex, _collectFormData(props.data)]));
						setLastInputTouched(e);
						handleBlur(e, props);
						props?.onBlur();
					}}
					onFocus={(e) => {
						addOrUpdateCounter(e, props);
					}}
				>
					{props.authenticity_token && (
						<div style={formTokenStyle}>
							<input name="utf8" type="hidden" value="&#x2713;" />
							<input name="authenticity_token" type="hidden" value={props.authenticity_token} />
							<input name="task_id" type="hidden" value={props.task_id} />
						</div>
					)}
					{items}

					{!props.disableFooterButtons && (
						<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
							<Button
								color="inherit"
								disabled={props.activeStep === 0}
								style={{ display: `${props.activeStep === 0 ? 'none' : 'initial'}` }}
								onClick={props.GoBack}
								sx={{ mr: 1 }}
							>
								Back
							</Button>
							<Box sx={{ flex: '1 1 auto' }} />

							<Button onClick={() => handleSubmit()} id={'form-finish-button'}>
								{!props?.read_only ? (
									props.activeStep === props.totalSteps?.length - 1 ? (
										'Finish'
									) : (
										'Next'
									)
								) : (
									<></>
								)}
							</Button>
						</Box>
					)}
				</form>
				<input
					type="hidden"
					id={`${props?.formID || 'userForm'}-saveButton`}
					onClick={handleSubmit}
				/>
				<input
					type="hidden"
					id="hiddenBlurTriggerInput"
					onClick={() => {
						dispatch(setFormData([props.formIndex, _collectFormData(props.data)]));
					}}
				/>
			</div>
		</div>
	);
};

export default injectIntl(ReactForm);
ReactForm.defaultProps = { validateForCorrectness: false };
