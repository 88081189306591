import { useEffect, useState } from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import StyledWrapper from './style';
import { StageTemplateLogic } from './StageTemplateLogic';
import { ProcessMetaInfo } from './ProcessMetaInfo';
import { StageAppSelection } from './components/StageAppSelection/StageAppSelection';
import {
	TEMP_APP_SAVE_ERR_MSG,
	TEMP_STAGE_SAVE_ERR_MSG
} from '../../constants/appProcessEditWarningMessage';
import { ItemPageTitle } from '../../components/ItemPageTitle';
import { CustomModal } from '../../components/Modal';
import {
	clearFormSchema,
	setFormReadOnly,
	setFormMetaInfo
} from '../../components/FormBuilderLibrary/slice';
import {
	useCreateStageMutation,
	useUpdateStageMutation
} from '../../services/endpoints/stagesEndpoints';
import {
	useCreateApplicationProcessMutation,
	useGetApplicationProcessQuery,
	useUpdateApplicationProcessMutation
} from '../../services/endpoints/programsEndpoints';
import { useGetIndividualStageQuery } from '../../services/endpoints/stagesEndpoints';
import SubmissionStageIcon from '../../assets/customIcons/submissionStageIcon';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import { TabPanel, a11yProps } from '../../components/TabPanel';
import SaveDialog from '../../components/SaveDialog/SaveDialog';
import BackDialog from '../../components/BackDialog/BackDialog';

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LastEdited from '../../components/LastEdited/LastEdited';
import useCreateCoreInfo from '../../hooks/createCoreInfo';
import { usePermissions } from '../../hooks/usePermissions';
import ApplicantProcessIcon from '../../assets/customIcons/applicantProcess';
import { TemplateUsage } from './TemplateUsage';
import { getEntityPrefix, isEmptyRTE } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';

const StageCreation = ({ openModal, closeModal, appProcessData }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const formMetaInfo = useSelector((state) => state.form.formMetaInfo);
	const toReview = useSelector((state) => state.form.to_review);
	const isReadOnly = useSelector((state) => state.form.isReadOnly);

	const hasSubmissionStage = appProcessData?.find((e) => e?.type === 'Submission Stage');

	const [value, setValue] = useState(0);
	const [pageTitle, setPageTitle] = useState('');
	const [chosenView, setChosenView] = useState(<></>);
	const [backModal, setBackModal] = useState(false);
	const [alertBalloon, setAlertBalloon] = useState(false);
	const [open, setOpen] = useState(false);
	const [readOnly, setReadOnly] = useState(isReadOnly);
	const [MetaInfoChanged, setMetaInfoChanged] = useState(false);
	const [hideButtons, setHideButtons] = useState(true);
	const stageCreationParam = searchParams.get('stageCreation');
	const stageID = searchParams.get('stageId');
	const appProcessID = searchParams.get('processId');
	let tabs = [
		`${stageCreationParam === 'process' ? 'SETUP APPLICATION PROCESS' : 'SETUP STAGE'}`,
		// `${stageCreationParam === 'evaluation' && 'SET CONTENT TO REVIEW'}`,
		`USAGE`
	];
	tabs = tabs?.filter((e) => e !== 'false');

	const { hasEditPermissions } = usePermissions();

	const [createStage] = useCreateStageMutation();
	const [updateStage] = useUpdateStageMutation();
	const {
		data: individualStage,
		isLoading,
		error: stageApiError
	} = appProcessID
		? useGetApplicationProcessQuery(
				{
					programID: appProcessID,
					apiToken
				},
				{ refetchOnMountOrArgChange: true }
		  )
		: useGetIndividualStageQuery(
				stageID
					? {
							stageID: stageID,
							apiToken
					  }
					: skipToken,
				{
					refetchOnMountOrArgChange: true
				}
		  );
	const [createAppProcess] = useCreateApplicationProcessMutation();
	const [updateAppProcess] = useUpdateApplicationProcessMutation();
	const { createCoreInfo } = useCreateCoreInfo();

	const handleAlert = (message, status = 'error') => {
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon((prevAlertBalloon) => ({
				...prevAlertBalloon,
				isOpen: false
			}));
		}, 3000);
	};

	const handleChange = (event, newValue) => {
		searchParams.set('viewType', newValue === 0 ? 'builder' : 'usage');
		setSearchParams(searchParams);
	};

	const handleClose = () => {
		setSearchParams(searchParams.delete('stageCreation'));
		closeModal && closeModal();
		setOpen(false);
	};

	const handleCloseModal = () => {
		if (MetaInfoChanged) {
			setBackModal({ isOpen: true });
		} else {
			handleClose();
		}
	};

	const getPageTitle = () => {
		const formIcon =
			searchParams.get('stageCreation') === 'application' ? (
				<SubmissionStageIcon isWarning isTemplate />
			) : searchParams.get('stageCreation') === 'evaluation' ? (
				<ReviewStageIcon isWarning isTemplate width={'20px'} />
			) : (
				<ApplicantProcessIcon isWarning isTemplate />
			);

		const formType =
			searchParams.get('stageCreation') === 'application'
				? 'Submission Stage'
				: searchParams.get('stageCreation') === 'evaluation'
				? 'Evaluation Stage'
				: 'Application Process';
		const pageTitle = (
			<div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
				{formIcon} {`${formType}`}
				<span style={{ color: '#ED6C02' }}>Template</span>
				{`: ${formMetaInfo?.title || ''} ${formMetaInfo?.id ? `[${formMetaInfo?.id}]` : '[]'}`}
			</div>
		);
		return pageTitle;
	};

	const handleSaveAppProcess = async () => {
		const payload = {
			program: null,
			process_name: formMetaInfo?.title,
			instructions: formMetaInfo?.instructions,
			description: formMetaInfo?.description,
			stage_order: formMetaInfo?.sequential ? formMetaInfo?.order : [],
			is_template: true
		};
		let createStageOptions = {
			id: searchParams.get('processId'),
			payload: payload,
			apiToken: apiToken
		};

		if (formMetaInfo && (formMetaInfo.title === '' || isEmptyRTE(formMetaInfo.instructions))) {
			handleAlert(TEMP_APP_SAVE_ERR_MSG);
			return false;
		}

		// CHECK IF REVIEW STAGE IS FIRST IN SEQUENTIAL STEPS
		const firstInOrder = individualStage?.stages?.find(
			(e) => e?.stage_id === formMetaInfo?.order?.[0]
		);
		if (formMetaInfo?.sequential && firstInOrder?.stage_type === 'evaluation') {
			handleAlert("Evaluation Stage can't be first in sequence");
			return;
		}

		if (formMetaInfo?.title) {
			if (searchParams.get('processId') && formMetaInfo) {
				await updateAppProcess(createStageOptions)
					.unwrap()
					.then(async () => {
						handleAlert('Application process template was successfully saved.', 'success');
						return true;
					})
					.catch(() => {
						return false;
					});
			} else {
				formMetaInfo &&
					(await createAppProcess(createStageOptions)
						.unwrap()
						.then(async (res) => {
							searchParams.set('processId', res?.process_id);
							setSearchParams(searchParams);
							handleAlert('Application process template was successfully created.', 'success');
							return true;
						})
						.catch(() => {
							return false;
						}));
			}
			return true;
		} else {
			const form = document.getElementsByClassName('meta-info-form')[0];
			form.click();
			return false;
		}
	};

	const handleSaveStage = async () => {
		const payload = {
			stage_type: searchParams.get('stageCreation'),
			stage_name: formMetaInfo?.title,
			instructions: formMetaInfo?.instructions,
			description: formMetaInfo?.description,
			review_info: searchParams.get('stageCreation') === 'evaluation' ? toReview : [],
			form_order: formMetaInfo?.sequential ? formMetaInfo?.order : [],
			is_template: true,
			process: searchParams.get('processId')
		};
		let createStageOptions = {
			stageID: searchParams.get('stageId'),
			payload: payload,
			apiToken: apiToken
		};

		if (formMetaInfo && (formMetaInfo.title === '' || isEmptyRTE(formMetaInfo.instructions))) {
			handleAlert(TEMP_STAGE_SAVE_ERR_MSG);
			return false;
		}

		if (formMetaInfo?.title) {
			if (searchParams.get('stageId')) {
				formMetaInfo &&
					(await updateStage(createStageOptions)
						.unwrap()
						.then(async () => {
							handleAlert(
								`${
									stageCreationParam === 'application' ? 'Submission stage' : 'Evaluation stage'
								} template was successfully saved.`,
								'success'
							);
							return true;
						})
						.catch(() => {
							return false;
						}));
			} else {
				formMetaInfo &&
					(await createStage(createStageOptions)
						.unwrap()
						.then(async (res) => {
							// Create Core Info
							if (!hasSubmissionStage && res?.program) {
								await createCoreInfo(res);
							}
							searchParams.set('stageId', res?.stage_id);
							setSearchParams(searchParams);

							handleAlert(
								`${
									stageCreationParam === 'application' ? 'Submission stage' : 'Evaluation stage'
								} template was successfully created.`,
								'success'
							);
							return true;
						})
						.catch(() => {
							return false;
						}));
			}
			return true;
		} else {
			const form = document.getElementsByClassName('meta-info-form')[0];
			form.click();
			return false;
		}
	};

	const selectView = (view) => {
		switch (view) {
			case 0:
				return (
					<>
						<StyledWrapper.MetaTitleContainer>Settings</StyledWrapper.MetaTitleContainer>

						<ProcessMetaInfo
							readOnly={readOnly || !hasEditPermissions}
							individualStage={individualStage}
							isLoading={isLoading}
							isAppProcess={stageCreationParam === 'process'}
							setMetaInfoChanged={setMetaInfoChanged}
						/>
						<StageTemplateLogic
							isReadOnly={readOnly || !hasEditPermissions}
							isAppProcess={stageCreationParam === 'process'}
							individualStage={individualStage}
							isLoading={isLoading}
						/>
					</>
				);
			case 1:
				// return stageCreationParam === 'evaluation' ? (
				// 	<StageAppSelection readOnly={readOnly || !hasEditPermissions} />
				// ) : (
				return <TemplateUsage />;
			// );

			default:
				return <TemplateUsage />;
		}
	};

	useEffect(() => {
		dispatch(clearFormSchema());
		dispatch(setFormReadOnly(true));
	}, [stageCreationParam]);

	useEffect(() => {
		if (
			stageID &&
			stageCreationParam &&
			stageApiError?.status &&
			stageCreationParam !== 'process'
		) {
			navigate('/error');
		}
	}, [stageApiError]);

	useEffect(() => {
		setReadOnly(stageID || appProcessID ? isReadOnly : false);
	}, [isReadOnly]);

	useEffect(() => {
		setPageTitle(getPageTitle());
	}, [formMetaInfo]);

	useEffect(() => {
		const Prefix =
			stageCreationParam === 'process'
				? getEntityPrefix({
						type: 'application-process',
						isBaseTemplate: true
				  })
				: getEntityPrefix({
						type:
							individualStage?.stage_type === 'application'
								? 'submission-stage'
								: 'evaluation-stage',
						isBaseTemplate: true
				  });
		const stageInfo = {
			title: individualStage?.stage_name || individualStage?.process_name,
			id: individualStage?.unique_identifier && `${Prefix}${individualStage?.unique_identifier}`,
			description: individualStage?.description,
			instructions: individualStage?.instructions,
			type: individualStage?.stage_type,
			stage_forms: individualStage?.forms,
			bl: false
		};
		dispatch(setFormMetaInfo(stageInfo));
	}, [individualStage]);

	useEffect(() => {
		if (stageCreationParam) {
			searchParams.delete('formBuilder');
			setSearchParams(searchParams);
			setOpen(true);
		} else {
			handleClose();
		}
	}, [openModal, searchParams]);

	useEffect(() => {
		const subPage = searchParams.get('viewType');
		['usage', 'linkedTo', 'appliedTo'].includes(subPage) ? setValue(1) : setValue(0);
	}, [searchParams]);

	useEffect(() => {
		setChosenView(selectView(value));
		if (individualStage?.is_archive) {
			setReadOnly(true);
		}

		if (!individualStage?.is_archive) {
			setHideButtons(false);
		} else {
			setHideButtons(true);
		}
	}, [value, isLoading, readOnly, individualStage]);

	return (
		<CustomModal
			width={'100%'}
			height={'100%'}
			isOpen={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<StyledWrapper.OutermostContainer>
				<StyledWrapper.InnerContainer padding={'0px 55px'}>
					<StyledWrapper.BackIconContainer>
						<IconButton
							onClick={handleCloseModal}
							aria-label="delete"
							size="large"
							style={{ fontSize: '26px', background: '#E0E0E0' }}
						>
							<KeyboardBackspaceIcon style={{ fontSize: '26px' }} />
						</IconButton>
					</StyledWrapper.BackIconContainer>
					<ItemPageTitle removeGrayLine text={pageTitle || ''} isUnsaved={MetaInfoChanged} />

					<Box sx={{ width: '100%' }}>
						<Box data-testid={`reviewStage-box`} sx={{ padding: '0px 24px' }}>
							<Tabs
								value={value}
								onChange={(e, value) => {
									if (value === 1 && stageCreationParam === 'evaluation') {
										if (formMetaInfo?.id) {
											handleChange(e, value);
										} else {
											handleAlert('Please Complete Form Meta Info');
										}
									} else {
										handleChange(e, value);
									}
								}}
								aria-label="basic tabs example"
								style={{ width: 'fit-content' }}
							>
								{tabs.map((e, index) => {
									return (
										<Tab
											iconPosition="start"
											data-testid={`reviewStage-tab-${index}`}
											key={`FormTab-${index}`}
											label={`${e}`}
											{...a11yProps(e + 1)}
										/>
									);
								})}
							</Tabs>
						</Box>

						<TabPanel data-testid={`reviewStage-tab-panel`} value={value} index={value}>
							<StyledWrapper.FormHeader>
								<LastEdited
									data={{
										...individualStage?.updated_by_user,
										time: individualStage?.last_updated_on
									}}
									editingRow={!readOnly}
								/>
								{!hideButtons && value !== 1 && (
									<StyledWrapper.LastEditedActions>
										{readOnly && hasEditPermissions ? (
											<>
												{' '}
												{/* <IconButton
													aria-label="moreOptions"
													data-testid={'programDetails-moreOptions-icon'}
												>
													<MoreHorizIcon />
												</IconButton> */}
												<Button
													disabled={individualStage?.is_archive}
													data-testid={'programDetails-editbutton'}
													variant="outlined"
													onClick={() => setReadOnly(false)}
													startIcon={<EditIcon />}
												>
													Edit
												</Button>
											</>
										) : (
											hasEditPermissions && (
												<>
													<Button
														disabled={individualStage?.is_archive}
														variant="contained"
														component="label"
														data-testid={'programDetails-savebutton'}
														onClick={async () => {
															const result =
																stageCreationParam === 'process'
																	? await handleSaveAppProcess()
																	: await handleSaveStage();
															if (result) {
																setMetaInfoChanged(false);
																setReadOnly(true);
															}
														}}
													>
														Save
													</Button>
													<Button
														disabled={individualStage?.is_archive}
														variant="outlined"
														onClick={() => setReadOnly(true)}
														data-testid={'programDetails-cancelbutton'}
													>
														Cancel
													</Button>
												</>
											)
										)}
									</StyledWrapper.LastEditedActions>
								)}
							</StyledWrapper.FormHeader>
							<Collapse in={alertBalloon.isOpen}>
								<Alert
									severity={alertBalloon.status}
									action={
										<IconButton
											aria-label="close"
											color={alertBalloon.status}
											size="small"
											onClick={() => {
												setAlertBalloon((prevAlertBalloon) => ({
													...prevAlertBalloon,
													isOpen: false
												}));
											}}
										>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									}
									sx={{ mb: 2 }}
								>
									{alertBalloon?.message}
								</Alert>
							</Collapse>
							{chosenView}
						</TabPanel>
					</Box>
				</StyledWrapper.InnerContainer>
			</StyledWrapper.OutermostContainer>
			{backModal && MetaInfoChanged && (
				<BackDialog
					disableBackdropClick
					isOpen={backModal?.isOpen}
					title={'asd'}
					handleClose={handleClose}
					handleStay={() => setBackModal({ isOpen: false })}
				/>
			)}
		</CustomModal>
	);
};

export default StageCreation;
