import { useEffect, useState } from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import StyledWrapper from './style';
import { StageLogic } from './StageLogic';
import { ProcessMetaInfo } from './ProcessMetaInfo';
import { StageAppSelection } from './components/StageAppSelection/StageAppSelection';

import { ItemPageTitle } from '../../components/ItemPageTitle';
import { CustomModal } from '../../components/Modal';
import { clearFormSchema, setFormReadOnly } from '../../components/FormBuilderLibrary/slice';
import {
	useCreateStageMutation,
	useUpdateStageMutation
} from '../../services/endpoints/stagesEndpoints';
import {
	useCreateApplicationProcessMutation,
	useGetApplicationProcessQuery,
	useUpdateApplicationProcessMutation
} from '../../services/endpoints/programsEndpoints';
import { useGetIndividualStageQuery } from '../../services/endpoints/stagesEndpoints';
import SubmissionStageIcon from '../../assets/customIcons/submissionStageIcon';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import { TabPanel, a11yProps } from '../../components/TabPanel';
import BackDialog from '../../components/BackDialog/BackDialog';

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LastEdited from '../../components/LastEdited/LastEdited';
import { checkAllDateRanges, endOfDay, startOfDay, toISOString } from '../../utils/dateUtils';
import useCreateCoreInfo from '../../hooks/createCoreInfo';
import { usePermissions } from '../../hooks/usePermissions';
import { saveVariable } from '../../components/BaseLayout/slice';
import { isEmptyRTE } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';
import {
	STAGE_APPLICATION,
	STAGE_CREATION_PARAM,
	STAGE_PROCESS
} from '../ProgramSetup/subPages/StagesAndForms/constants';
import { checkDateRangeValidity, stageDateValidation } from './utils';
import {
	SETUP_APP_SAVE_ERR_MSG,
	SETUP_STAGE_SAVE_ERR_MSG
} from '../../constants/appProcessEditWarningMessage';

const StageCreation = ({ openModal, closeModal, appProcessData, refetch, handleAlertOuter }) => {
	let { programID } = useParams();
	programID = programID?.replace('p-', '');
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const previousPath = useSelector((state) => state.globalProgramVariables?.previousPath);
	const username = useSelector((state) => state?.login?.user?.username);
	const formMetaInfo = useSelector((state) => state.form.formMetaInfo);
	const toReview = useSelector((state) => state.form.to_review);
	const formHasChanged = useSelector((state) => state.form.hasChanged);
	const isReadOnly = useSelector((state) => state.form.isReadOnly);
	const tabs = ['SETUP STAGE', 'SET CONTENT TO REVIEW'];
	const hasSubmissionStage = appProcessData?.find((e) => e?.type === 'Submission Stage');

	const [value, setValue] = useState(0);
	const [pageTitle, setPageTitle] = useState('');
	const [chosenView, setChosenView] = useState(<></>);
	const [backModal, setBackModal] = useState(false);
	const [alertBalloon, setAlertBalloon] = useState(false);
	const [open, setOpen] = useState(false);
	const [readOnly, setReadOnly] = useState(isReadOnly);
	const [MetaInfoChanged, setMetaInfoChanged] = useState(false);
	const stageCreationParam = searchParams.get(STAGE_CREATION_PARAM);
	const stageID = searchParams.get('stageId');
	const appProcessID = searchParams.get('processId');

	const isStageProcess = stageCreationParam === STAGE_PROCESS;
	const isStageApplication = stageCreationParam === STAGE_APPLICATION;

	const { hasEditPermissions } = usePermissions();

	const [createStage] = useCreateStageMutation();
	const [updateStage] = useUpdateStageMutation();
	const {
		data: individualStage,
		isLoading,
		error: stageApiError
	} = appProcessID
		? useGetApplicationProcessQuery(
				{
					programID: appProcessID,
					apiToken
				},
				{ refetchOnMountOrArgChange: true }
		  )
		: useGetIndividualStageQuery(
				stageID
					? {
							stageID: stageID,
							apiToken
					  }
					: skipToken,
				{ refetchOnMountOrArgChange: true }
		  );
	const [createAppProcess] = useCreateApplicationProcessMutation();
	const [updateAppProcess] = useUpdateApplicationProcessMutation();
	const { createCoreInfo } = useCreateCoreInfo();

	const handleAlert = (message, status = 'error') => {
		setAlertBalloon({ isOpen: true, message, status });

		setTimeout(() => {
			setAlertBalloon((prevAlertBalloon) => ({
				...prevAlertBalloon,
				isOpen: false
			}));
		}, 3000);
	};

	const handleClose = () => {
		setSearchParams(searchParams.delete('stageCreation'));
		closeModal && closeModal();
		setOpen(false);
	};

	const handleCloseModal = () => {
		if (MetaInfoChanged) {
			setBackModal({ isOpen: true });
		} else if (previousPath) {
			const { pathname, search } = previousPath;
			dispatch(saveVariable(['previousPath', null]));
			navigate(`${pathname}${search}`);
		} else {
			handleClose();
		}
	};

	const getPageTitle = () => {
		const formIcon = isStageApplication ? (
			<SubmissionStageIcon />
		) : (
			<ReviewStageIcon width={'20px'} />
		);

		let pageTitle = '';
		if (isStageProcess) {
			pageTitle = `Application Process for Program: ${formMetaInfo?.programTitle}`;
		} else {
			const formType = isStageApplication ? 'Submission' : 'Evaluation';
			pageTitle = (
				<div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
					{formIcon}{' '}
					{`${formType} Stage: ${formMetaInfo?.title || ''} ${
						formMetaInfo?.id ? `[${formMetaInfo?.id}]` : '[]'
					}`}
				</div>
			);
		}

		return pageTitle;
	};

	const handleSaveAppProcess = async () => {
		const payload = {
			program: {
				programId: programID
			},
			process_name: `Application Process for Program: ${formMetaInfo?.programTitle}`,
			instructions: formMetaInfo?.instructions,
			start_date: formMetaInfo?.startDate,
			end_date: formMetaInfo?.endDate,
			stage_order: formMetaInfo?.sequential ? formMetaInfo?.order : []
		};
		let createStageOptions = {
			id: searchParams.get('processId'),
			payload: payload,
			apiToken: apiToken
		};

		if (
			formMetaInfo &&
			(formMetaInfo.programTitle === '' || isEmptyRTE(formMetaInfo.instructions))
		) {
			handleAlert(SETUP_APP_SAVE_ERR_MSG);
			return false;
		}

		// CHECK IF REVIEW STAGE IS FIRST IN SEQUENTIAL STEPS
		const firstInOrder = individualStage?.stages?.find(
			(e) => e?.stage_id === formMetaInfo?.order?.[0]
		);
		if (formMetaInfo?.sequential && firstInOrder?.stage_type === 'evaluation') {
			handleAlert("Evaluation Stage can't be first in sequence");
			return false;
		}

		// Check Dates
		const datesCorrect = checkAllDateRanges(
			individualStage?.stages,
			formMetaInfo?.startDate,
			formMetaInfo?.endDate
		);
		if (!datesCorrect) {
			handleAlert('There are current Stages outside Date Range!');
			return false;
		}

		const datesValid = new Date(formMetaInfo?.startDate) < new Date(formMetaInfo?.endDate);
		if (formMetaInfo?.instructions && datesValid) {
			if (searchParams.get('processId') && formMetaInfo) {
				await updateAppProcess(createStageOptions)
					.unwrap()
					.then(async () => {
						handleAlert('Application process was successfully saved.', 'success');

						return true;
					})
					.catch(() => {
						return false;
					});
			} else {
				formMetaInfo &&
					(await createAppProcess(createStageOptions)
						.unwrap()
						.then(async (res) => {
							searchParams.set('processId', res?.process_id);
							setSearchParams(searchParams);

							handleAlert('Application process was successfully created.', 'success');
							return true;
						})
						.catch(() => {
							return false;
						}));
			}
			return true;
		} else if (!datesValid) {
			handleAlert('Start/End dates are invalid.');
			return false;
		} else {
			const form = document.getElementsByClassName('meta-info-form')[0];
			form.click();
			handleAlert('Application Process Overall Instructions are required.');

			return false;
		}
	};

	const handleSaveStage = async () => {
		const payload = {
			program: {
				programId: programID
			},
			stage_type: stageCreationParam,
			stage_name: formMetaInfo?.title,
			instructions: formMetaInfo?.instructions,
			start_date: formMetaInfo?.startDate,
			end_date: formMetaInfo?.endDate,
			review_info: stageCreationParam === 'evaluation' ? toReview : [],
			form_order: formMetaInfo?.sequential ? formMetaInfo?.order : []
		};
		let createStageOptions = {
			stageID: searchParams.get('stageId'),
			payload: payload,
			apiToken: apiToken
		};

		if (formMetaInfo && (formMetaInfo.title === '' || isEmptyRTE(formMetaInfo.instructions))) {
			handleAlert(SETUP_STAGE_SAVE_ERR_MSG);
			return false;
		}

		const datesValid = stageDateValidation(formMetaInfo);
		const datesWithinRange = checkDateRangeValidity(formMetaInfo);
		if (formMetaInfo?.title && formMetaInfo?.instructions && datesValid && datesWithinRange) {
			if (searchParams.get('stageId')) {
				formMetaInfo &&
					(await updateStage(createStageOptions)
						.unwrap()
						.then(async () => {
							handleAlert(
								`${
									isStageApplication ? 'Submission stage' : 'Evaluation stage'
								} was successfully saved.`,
								'success'
							);
							return true;
						})
						.catch(() => {
							return false;
						}));
			} else {
				formMetaInfo &&
					(await createStage(createStageOptions)
						.unwrap()
						.then(async (res) => {
							// Create Core Info
							if (!hasSubmissionStage) {
								await createCoreInfo(res);
							}
							searchParams.set('stageId', res?.stage_id);
							setSearchParams(searchParams);

							handleAlert(
								`${
									isStageApplication ? 'Submission stage' : 'Evaluation stage'
								} was successfully created.`,
								'success'
							);
							return true;
						})
						.catch(() => {
							return false;
						}));
			}
			return true;
		} else if (!datesValid) {
			handleAlert('End time must be after start time.');
			return false;
		} else if (!datesWithinRange) {
			handleAlert('Start/End dates are invalid.');
			return false;
		} else {
			const form = document.getElementsByClassName('meta-info-form')[0];
			form.click();
			handleAlert('Stage Instruction is required.');
			return false;
		}
	};

	const selectView = (view) => {
		switch (view) {
			case 0:
				return (
					<>
						<StyledWrapper.MetaTitleContainer>Settings</StyledWrapper.MetaTitleContainer>

						<ProcessMetaInfo
							readOnly={readOnly || !hasEditPermissions}
							individualStage={individualStage}
							isLoading={isLoading}
							isAppProcess={isStageProcess}
							setMetaInfoChanged={setMetaInfoChanged}
							appProcessData={appProcessData}
						/>
						<StageLogic
							isReadOnly={readOnly || !hasEditPermissions}
							isAppProcess={isStageProcess}
							individualStage={
								isStageProcess
									? appProcessData?.filter((e) => e?.type?.includes('Stage'))
									: individualStage
							}
							isLoading={isLoading}
						/>
					</>
				);
			case 1:
				return <StageAppSelection readOnly={readOnly || !hasEditPermissions} />;
			default:
				return <></>;
		}
	};

	useEffect(() => {
		dispatch(clearFormSchema());
		dispatch(setFormReadOnly(true));
	}, [stageCreationParam]);

	useEffect(() => {
		if (stageID && stageCreationParam && stageApiError?.status && !isStageProcess) {
			navigate('/error');
		}
	}, [stageApiError]);

	useEffect(() => {
		setReadOnly(stageID || appProcessID ? isReadOnly : false);
	}, [isReadOnly]);

	useEffect(() => {
		setPageTitle(getPageTitle());
	}, [formMetaInfo]);

	useEffect(() => {
		if (stageCreationParam) {
			searchParams.delete('formBuilder');
			setSearchParams(searchParams);
			setOpen(true);
		} else {
			handleClose();
		}
	}, [openModal, searchParams]);

	useEffect(() => {
		setChosenView(selectView(value));
		if (!hasEditPermissions && individualStage?.in_use) {
			setReadOnly(true);
		}
	}, [value, isLoading, readOnly, individualStage]);

	return (
		<CustomModal
			width={'100%'}
			height={'100%'}
			isOpen={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<StyledWrapper.OutermostContainer>
				<StyledWrapper.InnerContainer padding={'0px 55px'}>
					<StyledWrapper.BackIconContainer>
						<IconButton
							onClick={handleCloseModal}
							aria-label="delete"
							size="large"
							style={{ fontSize: '26px', background: '#E0E0E0' }}
						>
							<KeyboardBackspaceIcon style={{ fontSize: '26px' }} />
						</IconButton>
					</StyledWrapper.BackIconContainer>
					<ItemPageTitle removeGrayLine text={pageTitle || ''} isUnsaved={MetaInfoChanged} />

					<Box sx={{ width: '100%' }}>
						{!isStageProcess && !isStageApplication && (
							<Box data-testid={`reviewStage-box`} sx={{ padding: '0px 24px' }}>
								<Tabs
									value={value}
									onChange={(e, value) => {
										if (value === 1) {
											const datesValid =
												new Date(formMetaInfo?.startDate) <= new Date(formMetaInfo?.endDate);
											if (formMetaInfo?.title && formMetaInfo?.instructions && datesValid) {
												setValue(value);
											} else {
												handleAlert('Please Complete Form Meta Info');
											}
										} else {
											setValue(value);
										}
									}}
									aria-label="basic tabs example"
									style={{ width: 'fit-content' }}
								>
									{tabs.map((e, index) => {
										return (
											<Tab
												iconPosition="start"
												data-testid={`reviewStage-tab-${index}`}
												key={`FormTab-${index}`}
												label={`${e}`}
												{...a11yProps(e + 1)}
											/>
										);
									})}
								</Tabs>
							</Box>
						)}

						<TabPanel data-testid={`reviewStage-tab-panel`} value={value} index={value}>
							<StyledWrapper.FormHeader>
								<LastEdited
									data={{
										...individualStage?.updated_by_user,
										time: individualStage?.last_updated_on
									}}
									editingRow={!readOnly}
								/>
								<StyledWrapper.LastEditedActions>
									{readOnly && hasEditPermissions ? (
										<>
											{' '}
											{/* <IconButton
												aria-label="moreOptions"
												data-testid={'programDetails-moreOptions-icon'}
											>
												<MoreHorizIcon />
											</IconButton> */}
											<Button
												disabled={!(hasEditPermissions || individualStage?.in_use)}
												data-testid={'programDetails-editbutton'}
												variant="outlined"
												onClick={() => setReadOnly(false)}
												startIcon={<EditIcon />}
											>
												Edit
											</Button>
										</>
									) : (
										hasEditPermissions && (
											<>
												<Button
													disabled={!(hasEditPermissions || individualStage?.in_use)}
													variant="contained"
													component="label"
													data-testid={'programDetails-savebutton'}
													onClick={async () => {
														const result = isStageProcess
															? await handleSaveAppProcess()
															: await handleSaveStage();
														if (result) {
															setMetaInfoChanged(false);
															setReadOnly(true);
															refetch();
														}
													}}
												>
													Save
												</Button>
												<Button
													disabled={!(hasEditPermissions || individualStage?.in_use)}
													variant="outlined"
													onClick={() => setReadOnly(true)}
													data-testid={'programDetails-cancelbutton'}
												>
													Cancel
												</Button>
											</>
										)
									)}
								</StyledWrapper.LastEditedActions>
							</StyledWrapper.FormHeader>
							<Collapse in={alertBalloon.isOpen}>
								<Alert
									severity={alertBalloon.status}
									action={
										<IconButton
											aria-label="close"
											color={alertBalloon.status}
											size="small"
											onClick={() => {
												setAlertBalloon((prevAlertBalloon) => ({
													...prevAlertBalloon,
													isOpen: false
												}));
											}}
										>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									}
									sx={{ mb: 2 }}
								>
									{alertBalloon?.message}
								</Alert>
							</Collapse>
							{chosenView}
						</TabPanel>
					</Box>
				</StyledWrapper.InnerContainer>
			</StyledWrapper.OutermostContainer>
			{backModal && MetaInfoChanged && (
				<BackDialog
					disableBackdropClick
					isOpen={backModal?.isOpen}
					title={'asd'}
					handleClose={handleClose}
					handleStay={() => setBackModal({ isOpen: false })}
				/>
			)}
		</CustomModal>
	);
};

export default StageCreation;
