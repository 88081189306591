import ID from './UUID';
import { MyInput } from './customComponents/MUI_Input';
import { MUIDropdown } from './customComponents/MUI_Dropdown';
import { MUIStatePicker, STATES } from './customComponents/MUI_StatePicker';
import { MUINumberInput } from './customComponents/MUI_NumberInput';
import { MUICalculator } from './customComponents/MUI_Calculator';
import { MUITextArea } from './customComponents/MUI_TextArea';
import { MUICheckbox } from './customComponents/MUI_Checkbox';
import { MUIRadioButton } from './customComponents/MUI_RadioButton';
import { MUIDatePicker } from './customComponents/MUI_DatePicker';
import { MUIEmail } from './customComponents/MUI_Email';
import { MUIPhoneNumber } from './customComponents/MUI_PhoneNumber';
import { MUIRating } from './customComponents/MUI_Rating';
import { MUITags } from './customComponents/MUI_Tags';
import { MUIGroupingStart } from './customComponents/MUI_GroupingStart';
import { MUIGroupingEnd } from './customComponents/MUI_GroupingEnd';
import { Instructions } from './customComponents/Instructions';
import { MUILinkInput } from './customComponents/MUI_LinkInput';
import { MUIStartEndDate } from './customComponents/MUI_StartEndDate';
import { MUIFileUpload } from './customComponents/MUI_FileUpload';
import { MUIFileAttachment } from './customComponents/MUI_FileAttachment';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import NumbersIcon from '@mui/icons-material/Numbers';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CalculateIcon from '@mui/icons-material/Calculate';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TodayIcon from '@mui/icons-material/Today';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import LinkIcon from '@mui/icons-material/Link';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TitleIcon from '@mui/icons-material/Title';
import SegmentIcon from '@mui/icons-material/Segment';
import LabelIcon from '@mui/icons-material/Label';
import StyleIcon from '@mui/icons-material/Style';
import LanguageIcon from '@mui/icons-material/Language';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DataArrayIcon from '@mui/icons-material/DataArray';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

export const items = [
	{
		group_name: 'Layout',
		key: 'LineBreak',
		icon: <HorizontalRuleIcon />
	},
	{
		group_name: 'Layout',
		key: 'MUI_GroupingStart',
		element: 'CustomElement',
		component: MUIGroupingStart,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_groupingStart_',
		name: 'Grouping Start',
		icon: <DataArrayIcon />,
		label: 'Grouping Start',
		custom_options: ['hideLabel']
	},
	{
		group_name: 'Layout',
		key: 'MUI_GroupingEnd',
		element: 'CustomElement',
		component: MUIGroupingEnd,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_groupingEnd_',
		name: 'Grouping End',
		icon: <DataArrayIcon />,
		label: 'Grouping End',
		custom_options: ['hideLabel']
	},
	{
		group_name: 'Layout',
		key: 'TwoColumnRow',
		icon: <ViewComfyIcon />
	},
	{
		group_name: 'Layout',
		key: 'ThreeColumnRow',
		icon: <ViewComfyIcon />
	},
	{
		group_name: 'Layout',
		key: 'MultiColumnRow',
		icon: <ViewComfyIcon />
	},

	{
		group_name: 'Typography',
		key: 'Instructions',
		element: 'CustomElement',
		component: Instructions,
		type: 'custom',
		forward_ref: true,
		field_name: 'instructions_',
		name: 'Instructions',
		icon: <ErrorOutlineIcon />,
		content: 'Instructions',
		custom_options: ['hideLabel']
	},

	{
		group_name: 'Typography',
		key: 'Header',
		icon: <TitleIcon />
	},
	{
		group_name: 'Typography',
		key: 'Paragraph',
		icon: <SegmentIcon />
	},
	{
		group_name: 'Typography',
		key: 'Label',
		icon: <LabelIcon />
	},
	{
		group_name: 'Typography',
		key: 'MUI_Tags',
		element: 'CustomElement',
		component: MUITags,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_tags_',
		name: 'Tags',
		icon: <StyleIcon />,
		label: 'Tags',
		options: [
			{ value: 'one', text: 'Option 1', key: `_option_${ID.uuid()}` },
			{ value: 'two', text: 'Option 2', key: `_option_${ID.uuid()}` }
		],
		custom_options: ['hideLabel']
	},
	{
		group_name: 'Typography',
		key: 'HyperLink',
		icon: <LanguageIcon />
	},

	{
		group_name: 'Controls',
		key: 'MUI_Input',
		element: 'CustomElement',
		component: MyInput,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_input_',
		name: 'Single Line Input',
		icon: <ExitToAppIcon />,
		label: 'Single Line Input',
		custom_options: ['hideLabel']
	},
	{
		group_name: 'Controls',
		key: 'MUI_TextArea',
		element: 'CustomElement',
		component: MUITextArea,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_textarea_',
		name: 'Paragraph Input',
		icon: <ExitToAppIcon />,
		label: 'Paragraph Input',
		custom_options: ['hideLabel']
	},
	{
		group_name: 'Controls',
		key: 'MUI_Email',
		element: 'CustomElement',
		component: MUIEmail,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_email_',
		name: 'Email Input',
		icon: <AlternateEmailIcon />,
		label: 'Email Input',
		custom_options: ['hideLabel'],
		excludeReview: true
	},

	{
		group_name: 'Controls',
		key: 'MUI_PhoneNumber',
		element: 'CustomElement',
		component: MUIPhoneNumber,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_phone_',
		name: 'Phone Number',
		icon: <LocalPhoneIcon />,
		label: 'Phone Number',
		custom_options: ['hideLabel'],
		excludeReview: true
	},
	{
		group_name: 'Controls',
		key: 'MUI_NumberInput',
		element: 'CustomElement',
		component: MUINumberInput,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_number_',
		name: 'Number Input',
		icon: <NumbersIcon />,
		label: 'Number Input',
		custom_options: ['min_and_max', 'hideLabel']
	},
	{
		group_name: 'Controls',
		key: 'MUI_Dropdown',
		element: 'CustomElement',
		component: MUIDropdown,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_dropdown_',
		name: 'Dropdown',
		icon: <ExpandCircleDownOutlinedIcon />,
		label: 'Dropdown',
		options: [
			{ value: 'one', text: 'Option 1', key: `_option_${ID.uuid()}` },
			{ value: 'two', text: 'Option 2', key: `_option_${ID.uuid()}` }
		],
		custom_options: ['hideLabel']
	},
	{
		group_name: 'Controls',
		key: 'MUI_Checkbox',
		element: 'CustomElement',
		component: MUICheckbox,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_checkbox_',
		name: 'Checkboxes',
		icon: <CheckBoxOutlinedIcon />,
		label: 'Checkboxes Label',
		options: [
			{ value: 'one', text: 'Option 1', key: `option_${ID.uuid()}` },
			{ value: 'two', text: 'Option 2', key: `option_${ID.uuid()}` }
		],
		custom_options: ['hideLabel']
	},
	{
		group_name: 'Controls',
		key: 'MUI_RadioButton',
		element: 'CustomElement',
		component: MUIRadioButton,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_radiobutton_',
		name: 'Radio Button',
		icon: <RadioButtonCheckedIcon />,
		label: 'Radio Button Label',
		options: [
			{ value: 'one', text: 'Option 1', key: `option_${ID.uuid()}` },
			{ value: 'two', text: 'Option 2', key: `option_${ID.uuid()}` }
		],
		custom_options: ['hideLabel']
	},
	{
		group_name: 'Controls',
		key: 'StatePicker',
		element: 'CustomElement',
		component: MUIStatePicker,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_statepicker_dropdown_',
		name: 'State Picker',
		icon: <LocationCityIcon />,
		label: 'State Picker',
		custom_options: ['hideLabel'],
		options: STATES
	},
	{
		group_name: 'Controls',
		key: 'MUI_Calculator',
		element: 'CustomElement',
		component: MUICalculator,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_calculator_',
		name: 'Calculator',
		icon: <CalculateIcon />,
		label: 'Total:',
		custom_options: ['hideLabel']
	},
	{
		group_name: 'Controls',
		key: 'MUI_DatePicker',
		element: 'CustomElement',
		component: MUIDatePicker,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_datepicker_',
		name: 'Date Picker',
		icon: <TodayIcon />,
		label: 'DatePicker',
		custom_options: ['min_date', 'max_date', 'hideLabel'],
		excludeReview: true
	},
	{
		group_name: 'Controls',
		key: 'MUI_StartEndDate',
		element: 'CustomElement',
		component: MUIStartEndDate,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_startend_',
		name: 'Start/End Date',
		icon: <CalendarMonthIcon />,
		label: ' ',
		custom_options: ['min_date', 'max_date', 'hideLabel'],
		excludeReview: true
	},
	{
		group_name: 'Controls',
		key: 'MUI_Rating',
		element: 'CustomElement',
		component: MUIRating,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_rating_',
		name: 'Rating',
		icon: <StarHalfIcon />,
		label: 'Rating',
		custom_options: ['hideLabel'],
		excludeReview: true
	},
	{
		group_name: 'Controls',
		key: 'MUI_LinkInput',
		element: 'CustomElement',
		component: MUILinkInput,
		type: 'custom',
		forward_ref: true,
		field_name: 'linkInput_',
		name: 'Link Input',
		icon: <LinkIcon />,
		label: 'Link Input',
		custom_options: ['hideLabel'],
		excludeReview: true
	},
	{
		group_name: 'Controls',
		key: 'Image',
		icon: <ImageOutlinedIcon />,
		label: 'Image',
		excludeReview: true
	},
	{
		group_name: 'Upload/Download',
		key: 'MUI_FileUpload',
		element: 'CustomElement',
		component: MUIFileUpload,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_fileupload_',
		name: 'File Upload Dropbox',
		icon: <CloudUploadIcon />,
		label: 'File Upload Dropbox',
		custom_options: ['hideLabel'],
		help_message: 'Select an image from your computer or device.'
	},
	{
		group_name: 'Upload/Download',
		key: 'MUI_FileAttachment',
		element: 'CustomElement',
		component: MUIFileAttachment,
		type: 'custom',
		forward_ref: true,
		field_name: 'mui_fileattachment_',
		name: 'File Attachment (Applicant Download)',
		icon: <DownloadIcon />,
		label: 'File Attachment (Applicant Download)',
		custom_options: ['hideLabel']
	},
	{
		group_name: 'Upload/Download',
		key: 'Signature',
		icon: <LinkedCameraIcon />
	}
];
